import React from 'react';
import { Link } from 'react-router-dom';
import StackOfMolasses from './images/production/StackOfMolasses.jpeg';
import './NoMatch.scss';

function NoMatch() {
	return (
		<div>
			<h2>Nothing to see here...except for this Cookie Tower!</h2>
			<img src={StackOfMolasses} className="no-match-cookie" alt="Molasses Cookie Tower"/>
			<p>
				<Link to="/">Go to the home page</Link>
			</p>
		</div>
	);
}

export default NoMatch;
