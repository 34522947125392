import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import logo from './eat-ds-cookies-logo.svg';
import './Navbar.scss';

const routeToEventKey: Record<string, string> = {
	'/': 'Home',
	'/cookies': 'Cookies',
	'/our-story': 'OurStory',
	'/contact-us': 'ContactUs'
};

const getInitKey = (): string => {
	return routeToEventKey[window.location.pathname] || 'Home';
};

function NavBar() {
	const [key, setKey] = useState<string | null>(getInitKey());

	return (
		<>
			<div className="nav-logo">
				<img src={logo} alt="Eat D's Cookie Logo" />
			</div>

			<Nav className="justify-content-center" activeKey={key || ''} onSelect={key => setKey(key)}>
				<Nav.Item>
					<Nav.Link eventKey="Home" as={Link} to="/" >Home</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link eventKey="Cookies" as={Link} to="/cookies">Cookies</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link eventKey="OurStory" as={Link} to="/our-story">Our Story</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link eventKey="ContactUs" as={Link} to="/contact-us">Contact Us</Nav.Link>
				</Nav.Item>
			</Nav>
		</>
	);
}

export default NavBar;
