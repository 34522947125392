import React, { useState } from 'react';
import CardamonDoughBalls from './CarouselImages/Production/CardamonDoughBalls.jpeg';
import ChocolateChipCookiesCooling from './CarouselImages/Production/ChocolateChipCookiesCooling.jpeg';
import GingerMolasses from './CarouselImages/Production/GingerMolasses.jpeg';
import MadelinesCooling from './CarouselImages/Production/MadelinesCooling.jpeg';
import SnickerdoodleCookiesPile from './CarouselImages/Production/SnickerdoodleCookiesPile.jpeg';
import Carousel from 'react-bootstrap/Carousel';
import './Home.scss';

const carouselItemInfos = [
	{
		imageSrc: CardamonDoughBalls,
		imageAlt: 'Cardamon Dough Balls',
	},
	{
		imageSrc: ChocolateChipCookiesCooling,
		imageAlt: 'Chocolate Chip Cookies Cooling',
	},
	{
		imageSrc: GingerMolasses,
		imageAlt: 'Ginger Molasses',
	},
	{
		imageSrc: SnickerdoodleCookiesPile,
		imageAlt: 'Snickerdoodle Cookies',
	},
	{
		imageSrc: MadelinesCooling,
		imageAlt: 'Madelines Cooling Cookies',
	}
];

function Home() {
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex: number) => {
		setIndex(selectedIndex);
	};

	return (
		<>
			<p>
				We are a pickup and delivery cookie bakery located in
				Hampden in Baltimore, MD. All our cookies are baked to order and 
				typically take 24 hours to prepare. If you need cookies faster,
				please call <a href="tel:4434703620">(443) 470-3620</a>.
			</p>
			<p>
				Try one of our Always Cookies or one of our Seasonal Cookies.
			</p>

			<Carousel
				activeIndex={index}
				indicators={false}
				fade
				onSelect={handleSelect}
			>
				{carouselItemInfos.map(carouselItemInfo => {
					return <Carousel.Item key={carouselItemInfo.imageAlt}>
						<img
							className="d-block w-100"
							src={carouselItemInfo.imageSrc}
							alt={carouselItemInfo.imageAlt}
						/>
					</Carousel.Item>;
				})}
			</Carousel>
		</>
	);
}

export default Home;
