import React from 'react';
import Card from 'react-bootstrap/Card';

export interface CookieCardInterface {
	cookieName: string;
	imageSrc: string;
	imgAlt: string;
}

const CookieCard: React.FunctionComponent<CookieCardInterface> = (props): React.ReactElement => {
	// TODO: Extract to a custom component and add placeholder text
	// https://react-bootstrap.github.io/components/placeholder/
	return (
		<Card className='Cookie-card col-sm-4 d-flex flex-column'>
			<Card.Img variant="top" src={props.imageSrc} alt={props.imgAlt} />
			<Card.Body className="Cookie-card-body  d-flex  align-items-center justify-content-center">
				<Card.Title className="align-self-center">{props.cookieName}</Card.Title>
			</Card.Body>
		</Card>
	);
};

export default CookieCard;
