import React from 'react';
import CookieCard, { CookieCardInterface } from './Card/CookieCard';
import CardamomCookieWithLemonDrizzle from './images/production/CardamomCookieWithLemonDrizzle.jpg';
import ChocolateChipCoookie from './images/production/ChocolateChipCoookie.jpeg';
import CocoaCrackle from './images/production/CocoaCrackle.jpeg';
import FlourlessChocolateCookie from './images/production/FlourlessChocolateCookie.jpeg';
import GingerMolasses from './images/production/GingerMolasses.jpeg';
import LemonMadelines from './images/production/LemonMadelines.jpeg';
import SeasonalBunnySugarCookie from './images/production/SeasonalBunnySugarCookie.jpeg';
import SeasonalFallCookie from './images/production/SeasonalFallCookie.jpeg';
import Snickerdoodle from './images/production/Snickerdoodle.jpeg';
import ValentinesDaySugarCookies from './images/production/ValentinesDaySugarCookies.jpeg';
import './Cookies.scss';

const regularCards: CookieCardInterface[] = [
	{
		cookieName: 'Brown Butter Chocolate Chip',
		imageSrc: ChocolateChipCoookie,
		imgAlt: 'Chocolate chip cookie'
	},
	{
		cookieName: 'Snickerdoodle',
		imageSrc: Snickerdoodle,
		imgAlt: 'Snickerdoodle'
	},
	{
		cookieName: 'Giner Molasses',
		imageSrc: GingerMolasses,
		imgAlt: 'Ginger Molasses'
	},
	{
		cookieName: 'Flourless chocolate',
		imageSrc: FlourlessChocolateCookie,
		imgAlt: 'Flourless chocolate'
	},
	{
		cookieName: 'Cocoa Crackle',
		imageSrc: CocoaCrackle,
		imgAlt: 'Cocoa Crackle'
	},
	{
		cookieName: 'Cardamom with Lemon Drizzle',
		imageSrc: CardamomCookieWithLemonDrizzle,
		imgAlt: 'Cardamom with Lemon Drizzle'
	},
	{
		cookieName: 'Lemon Madelines',
		imageSrc: LemonMadelines,
		imgAlt: 'Lemon Madelines'
	},
];

const seasonalCards: CookieCardInterface[] = [
	{
		cookieName: 'Valentines Day',
		imageSrc: ValentinesDaySugarCookies,
		imgAlt: 'Heart Cookies'
	},
	{
		cookieName: 'Easter Cookies',
		imageSrc: SeasonalBunnySugarCookie,
		imgAlt: 'Easter Bunny Sugar Cookies'
	},
	{
		cookieName: 'Thanksgiving',
		imageSrc: SeasonalFallCookie,
		imgAlt: 'Thanksgiving'
	},
];

function Cookies() {
	return (
		<div className="col-12">
			<div className="row Cookie-group-row">
				<h4>Always Cookies</h4>
				{regularCards.map((cookieCard) => { return <CookieCard key={cookieCard.cookieName} {...cookieCard} />; })}
			</div>
			<div className="row Cookie-group-row">
				<h4>Seasonal Cookies</h4>
				{seasonalCards.map((cookieCard) => { return <CookieCard key={cookieCard.cookieName} {...cookieCard} />; })}
			</div>
		</div>
	);
}

export default Cookies;
