import React from 'react';

function OurStory() {
	return (
		<>
			<p>
				D has always loved cookies. When she was a kid, her mom would
				bake cookies for her every weekend. She would wake up to the smell
				of cookies baking in the oven, and she would run downstairs to
				the kitchen to get one. The cookies were always so warm and
				delicious, and they always made her happy.
			</p>
			<p>
				As D got older, she started baking cookies herself. She loved
				experimenting with different recipes and ingredients, and she
				always tried to make her cookies as delicious as her mom&apos;s.
				She would bake cookies for her friends and family, and they
				always loved them.
			</p>
			<p>
				D has now decided to share her love of cookies and the smiles 
				with Baltimore.
			</p>
		</>
	);
}

export default OurStory;
