import * as React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';

import Nav from './Navbar/Navbar';
import ContactUs from './ContactUs/ContactUs';
import Cookies from './Cookies/Cookies';
import Home from './Home/Home';
import NoMatch from './NoMatch/NoMatch';
import OurStory from './OurStory/OurStory';
import Body from './Body/Body';
import Footer from './Footer/Footer';

export default function App() {
	return (
		<div className="container">
			<div className="App">
				<header className="App-header">
					<Nav />
				</header>

				<div className="App-routes-container">
					{/* Routes nest inside one another. Nested route paths build upon
								parent route paths, and nested route elements render inside
								parent route elements. See the note about <Outlet> below. */}
					<Routes>
						<Route path="/" element={<Body />}>
							<Route index element={<Home />} />
							<Route path="cookies" element={<Cookies />} />
							<Route path="contact-us" element={<ContactUs />} />
							<Route path="our-story" element={<OurStory />} />

							{/* Using path="*"" means "match anything", so this route
										acts like a catch-all for URLs that we don't have explicit
										routes for. */}
							<Route path="*" element={<NoMatch />} />
						</Route>
					</Routes>
				</div>

				<Footer />
			</div>
		</div>
	);
}
